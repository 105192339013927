import { ReactNode, useState } from 'react'
import Typewriter from 'typewriter-effect'
import map from 'lodash/map'
import get from 'lodash/get'

import { ReactComponent as ChatIcon } from 'assets/chat-icon.svg'
import { TwoFactorT } from 'types/twoFactor'
import { getString } from 'controllers/localization'

interface Props {
  overallSummary?: string
  analysis?: TwoFactorT.AIAnalysisSection[]
  steps?: TwoFactorT.StepT[]
  locale: string
}

const SectionWrapper = ({
  label,
  children,
  type,
  description,
  locale
}: {
  label: string
  children?: ReactNode
  type?: 'transcript' | 'analysis'
  description?: string | { text: string; type: TwoFactorT.StepTypeT }[]
  locale: string
}) => {
  const renderDescription = () => {
    if (typeof description === 'string') {
      return (
        <p className='text-white text-sm whitespace-pre-wrap'>{description}</p>
      )
    } else if (description) {
      return (
        <div>
          {description.map((v, i) => (
            <p
              key={i}
              className={`text-sm whitespace-pre-wrap ${
                i % 2 === 0 ? 'text-blue-400' : 'text-white'
              }`}
            >
              {v.type === 'question' ? 'FACESIGN' : 'USER'}: {v.text}
            </p>
          ))}
        </div>
      )
    }
  }

  return (
    <div className='flex flex-row w-full'>
      <div className='mt-1'>
        <ChatIcon width='24px' height='24px' />
      </div>
      <div className='flex flex-col gap-4 ml-2 w-full'>
        <p className='font-medium text-md text-black'>{label}</p>
        {children}
        {description && (
          <div className='flex flex-col items-start justify-center gap-4 w-full'>
            <div className='flex flex-col gap-4 rounded-xl bg-black p-4 w-full'>
              <p className='uppercase text-gray-400 text-xs'>
                {type === 'analysis'
                  ? getString('recap_detailed', locale)
                  : getString('recap_transcript', locale)}
              </p>
              {renderDescription()}
            </div>
            {/* )} */}
          </div>
        )}
      </div>
    </div>
  )
}

const AnalysisContent = ({
  overallSummary,
  analysis,
  steps,
  locale
}: Props) => {
  const [writingDone, setWritingDone] = useState<boolean>(false)
  return (
    <div className='my-8 h-fit-content flex flex-col'>
      {overallSummary && (
        <SectionWrapper
          label={getString('recap_ai_analysis', locale)}
          locale={locale}
        >
          <Typewriter
            options={{
              cursor: '●'
            }}
            onInit={typewriter => {
              typewriter
                .callFunction(state => {
                  state.elements.cursor.style.color = '#16C95D'
                })
                .changeDelay(25)
                .typeString(overallSummary)
                .start()
                .callFunction(state => {
                  setWritingDone(true)
                  state.elements.cursor.style.display = 'none'
                })
            }}
          />
        </SectionWrapper>
      )}
      <div className={`flex-col gap-6 mt-6 ${writingDone ? 'flex' : 'hidden'}`}>
        {map(analysis, (v, i) => {
          return (
            <SectionWrapper
              key={i}
              label={v.title}
              type='analysis'
              description={v.longDescription}
              locale={locale}
            >
              <p className='text-md text-gray-700'>{v.shortDescription}</p>
            </SectionWrapper>
          )
        })}
        <SectionWrapper
          label={getString('recap_transcript', locale)}
          type='transcript'
          description={steps?.map(v => ({
            text: get(v, 'text', '') as string,
            type: v.sType
          }))}
          locale={locale}
        />
      </div>
    </div>
  )
}

export default AnalysisContent
