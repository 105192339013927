import { FC, ReactNode, useState, useMemo } from 'react'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap'
import { faWavePulse } from '@fortawesome/pro-light-svg-icons/faWavePulse'
import { faUserHair } from '@fortawesome/pro-light-svg-icons/faUserHair'
import { faLaptopMobile } from '@fortawesome/pro-light-svg-icons/faLaptopMobile'
import { faWaveformLines } from '@fortawesome/pro-light-svg-icons/faWaveformLines'

import { TwoFactorT } from 'types/twoFactor'
import { ReactComponent as IconCheck } from 'assets/iconCheckCircle.svg'
import { getString } from 'controllers/localization'

type Props = {
  recap: TwoFactorT.RecapT
  videoOpen?: boolean
  setVideoOpen?: (v: boolean) => void
  locale: string
}

type RowT = {
  title: string
  desc?: string
  children?: ReactNode
  icon?: ReactNode
}

const ICON_COLOR = '#718096'

const DataContentRow: FC<RowT> = ({ title, desc, children, icon }) => {
  return (
    <div className='w-full flex flex-row items-center gap-1'>
      <div className='flex flex-grow-0 flex-shrink-0 w-5 items-center justify-center'>
        {icon ?? (
          <div className='text-green-500'>
            <IconCheck />
          </div>
        )}
      </div>
      <div className='flex flex-col pl-3'>
        <span className='text-sm text-gray-700 font-bold'>{title}</span>
        {desc && (
          <span className='text-gray-700 text-sm whitespace-nowrap font-normal'>
            {desc}
          </span>
        )}
        <span className='w-full pt-[5px] text-xs text-gray-700'>
          {children}
        </span>
      </div>
    </div>
  )
}

const DataContent: FC<Props> = ({
  recap,
  locale
  // setVideoOpen
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)

  const analysis = useMemo(() => {
    const firstAnalysis = get(recap, 'aiAnalysisFirst')
    const analysis = get(recap, 'aiAnalysis', firstAnalysis)
    return analysis
  }, [recap])

  const renderLocation = () => {
    if (recap.location) {
      const locAr = []
      const city = get(recap, 'location.city.names.en')
      if (city) {
        locAr.push(city)
      }
      const country = get(recap, 'location.country.names.en')
      if (country) {
        locAr.push(country)
      }
      const loc = locAr.join(', ')
      return (
        <DataContentRow
          title={getString('recap_location', locale) + ':'}
          icon={<FontAwesomeIcon icon={faMap} color={ICON_COLOR} />}
        >
          <span>{loc}</span>
        </DataContentRow>
      )
    }
  }

  const renderDeviceInfo = () => {
    if (recap.deviceDetails) {
      const loc = `${recap.deviceDetails.platform}, ${recap.deviceDetails.browser} browser`
      return (
        <DataContentRow
          title={getString('recap_device', locale) + ':'}
          icon={<FontAwesomeIcon icon={faLaptopMobile} color={ICON_COLOR} />}
        >
          <span>{loc}</span>
        </DataContentRow>
      )
    }
  }

  const renderFacialAnalysis = () => {
    if (analysis) {
      // const face = get(recap.imageRekognition, [0, 'faces', 0])
      const ar = []
      const ageMin = get(analysis, 'ageMin')
      const ageHigh = get(analysis, 'ageMax')
      const age = `${ageMin} - ${ageHigh} ${getString(
        'recap_year_old',
        locale
      )}`
      ar.push(age)
      const gender = get(analysis, 'sex')
      if (gender) {
        if (gender === 'male')
          ar.push(
            getString(gender === 'male' ? 'recap_male' : 'recap_female', locale)
          )
      }
      // ar.push(
      //   get(face, 'Sunglasses.Value', false) ? 'wearing sunglasses' : null
      // )

      // ar.push(get(face, 'Eyeglasses.Value', false) ? 'wearing glasses' : null)
      // ar.push(get(face, 'Beard.Value', false) ? 'has a beard' : null)
      // ar.push(get(face, 'Mustache.Value', false) ? 'has a mustache' : null)
      // const confidence = round(get(face, 'Confidence', 0), 3)
      return (
        <DataContentRow
          title={getString('recap_facial', locale) + ':'}
          // desc={`Confidence ${confidence}%`}
          icon={<FontAwesomeIcon icon={faUserHair} color={ICON_COLOR} />}
        >
          <span>{ar.filter(v => v).join(', ')}</span>
        </DataContentRow>
      )
    }
  }

  const renderLiveness = () => {
    const v =
      analysis?.realPersonOrVirtual === 'real'
        ? getString('recap_real', locale)
        : getString('recap_fake', locale)
    return (
      <DataContentRow
        title={getString('recap_liveness', locale) + ':'}
        icon={<FontAwesomeIcon icon={faWavePulse} color={ICON_COLOR} />}
      >
        <span>{v}</span>
      </DataContentRow>
    )
  }

  // const renderVoiceAnalysis = () => {
  //   if (recap.imageRekognition && recap.imageRekognition.length > 0) {
  //     const face = get(recap.imageRekognition, [0, 'faces', 0])
  //     const gender = get(face, 'Gender.Value', '')
  //     return (
  //       <DataContentRow
  //         title='Voice analysis:'
  //         icon={<FontAwesomeIcon icon={faWaveformLines} color={ICON_COLOR} />}
  //       >
  //         <span>{gender} voice; native English (en-US)</span>
  //       </DataContentRow>
  //     )
  //   }
  // }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row pt-6 justify-between gap-1'>
        <div className='flex flex-col gap-5'>
          {renderFacialAnalysis()}
          {renderLiveness()}
          {renderLocation()}
          {/* {renderVoiceAnalysis()} */}
          {renderDeviceInfo()}
        </div>
        <div className='relative h-fit-content flex flex-col flex-shrink-0'>
          <img
            src={get(
              recap,
              ['userScreenshots', get(recap, 'aiAnalysis.bestPhotoIndex', 0)],
              get(recap, 'userAvatarUrl', '')
            )}
            alt=''
            className='scale-x-[-1] portrait:w-28 landscape:w-40 rounded ml-1'
            onLoad={() => setImageLoaded(true)}
          />
        </div>
      </div>
    </div>
  )
}

export default DataContent
