import get from 'lodash/get'
import {
  createClient,
  LiveClient,
  LiveTranscriptionEvents,
  LiveTranscriptionEvent
} from '@deepgram/sdk'
import jsLogger from 'js-logger'

const MILLISECOND = 1000
const PREFERRED_SAMPLE_RATE = 16000
const PACKET_DURATION = 500
const BUFFER_SIZE = 2048
const STEREO_FLOAT_TO_MONO_INT16 = 0x10000 / 4 - 1

class GladiaTranscriber {
  private _stream: MediaStream
  private _blobs: Blob[] = []
  private _dg?: LiveClient
  private _speach: string = ''
  private _onSpeach?: (s: string) => void
  private _onFinalDetected?: (transcript: string) => void
  private _interactionId: string
  private _source?: MediaStreamAudioSourceNode
  private _recorderNode?: AudioWorkletNode
  private _audioContext?: AudioContext
  private _gladiaKey?: string
  private _dgConnected: boolean = false
  private _keepaliveTimeer: number = 0
  private _isSpeaking: boolean = false
  private _onStartTalking: () => void
  private _timeTranscript: Record<string, string> = {}
  private _lang = 'en'
  private _sampleRate = 16000
  private _stopped = false
  private _socketUrl?: string
  private _socket?: WebSocket

  private _baseParams = {
    encoding: 'wav/pcm',
    bit_depth: 8,
    sample_rate: 8000,
    channels: 1,
    endpointing: 0.3,
    maximum_duration_without_endpointing: 15,
    language_config: {
      languages: ['en']
      // code_switching: true
    },
    pre_processing: {
      // audio_enhancer: true
    },
    realtime_processing: {
      words_accurate_timestamps: true,
      // custom_vocabulary: true,
      // custom_vocabulary_config: {
      //   vocabulary: ['Gladia']
      // },
      named_entity_recognition: true
      // sentiment_analysis: true
    },
    post_processing: {
      // summarization: true,
      // summarization_config: {
      //   type: 'general'
      // }
      // chapterization: true
    },
    messages_config: {
      // receive_partial_transcripts: true,
      receive_final_transcripts: true,
      // receive_speech_events: true,
      // receive_pre_processing_events: true,
      // receive_realtime_processing_events: true,
      // receive_post_processing_events: true,
      // receive_acknowledgments: true,
      receive_errors: true
      // receive_lifecycle_events: true
    }
    // callback: true,
    // callback_config: {
    //   url: 'https://callback.example',
    //   receive_partial_transcripts: true,
    //   receive_final_transcripts: true,
    //   receive_speech_events: true,
    //   receive_pre_processing_events: true,
    //   receive_realtime_processing_events: true,
    //   receive_post_processing_events: true,
    //   receive_acknowledgments: true,
    //   receive_errors: true,
    //   receive_lifecycle_events: true
    // }
  }

  constructor (
    stream: MediaStream,
    onSpeach: (s: string) => void,
    onFinalDetected: (transcript: string) => void,
    interactionId: string,
    apiKey: string,
    onStartTalking: () => void,
    lang: string
  ) {
    this._stream = stream
    this._onSpeach = onSpeach
    this._onFinalDetected = onFinalDetected
    this._interactionId = interactionId
    this._gladiaKey = apiKey
    this._onStartTalking = onStartTalking
    this._lang = lang
    this.start()

    // this._transcriberConnect(dgKey)
  }

  get speach () {
    return this._speach
  }

  private _resetSpeech = () => {
    jsLogger.log('reset speech')
    this._speach = ''
  }

  private _sendKeepalive = () => {
    if (this._dg && this._dg.isConnected()) {
      jsLogger.log('DG: send keepalive')
      this._dg.keepAlive()
    }
  }

  private _initiateSession = async () => {
    if (!this._gladiaKey) {
      jsLogger.error('GLADIA key is not provided')
      return
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-GLADIA-KEY': this._gladiaKey
    }
    const response = await fetch(`https://api.gladia.io/v2/live`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...this._baseParams,
        sample_rate: this._audioContext?.sampleRate,
        bit_depth: this._audioContext?.sampleRate ? 16 : undefined
      })
    })
    if (!response.ok) {
      const message = `${response.status}: ${
        (await response.text()) || response.statusText
      }`
      throw new Error(message)
    }
    return await response.json()
  }

  private _handleMessage = (message: object) => {
    jsLogger.log('GLADIA: handle message')
    const mType = get(message, 'type', '') as string
    const isFinal = get(message, 'data.is_final', false) as boolean
    if (mType === 'transcript' && isFinal) {
      const t = get(message, 'data.utterance.text', '')
      if (t !== '') {
        jsLogger.log('DG: transcript received ', {
          // message: t,
          t,
          isFinal
        })
        this._onStartTalking && this._onStartTalking()

        // this._timeTranscript[timeTrKey] = t
      }

      if (isFinal && t !== '') {
        this._speach = (this._speach + ' ' + t).trim()
        this._onSpeach && this._onSpeach(this._speach)
      } else if (t !== '') {
        this._onSpeach && this._onSpeach((this._speach + ' ' + t).trim())
      }

      if (isFinal) {
        jsLogger.log('DG: speech final detected', {
          speech: this._speach
          // timeTranscript: this._timeTranscript[timeTrKey]
        })
        const phrase = this._speach
        // this._speach !== '' ? this._speach : this._timeTranscript[timeTrKey]
        if (phrase && phrase.length > 0) {
          this._onFinalDetected && this._onFinalDetected(phrase)
          this._speach = ''
          this._isSpeaking = false
          this._onSpeach && this._onSpeach('')
        }
      }
    }
  }

  private _transcriberConnect = async () => {
    if (this._stopped) {
      jsLogger.debug('Gladia exit because already stopped')
      return
    }

    if (!this._socketUrl) {
      const { url, id } = await this._initiateSession()
      jsLogger.log('GLADIA: inital request sent', { url, id })
      this._socketUrl = url
    }

    if (!this._socketUrl) {
      jsLogger.error('GLADIA no socket url received')
      return
    }

    const socket = new WebSocket(this._socketUrl)
    this._socket = socket

    socket.addEventListener('open', function () {
      jsLogger.log('GLADIA socket connection opened')
    })

    socket.addEventListener('error', error => {
      jsLogger.error('GLADIA ERROR:', { error })
      setTimeout(() => this._transcriberConnect(), 1000)
    })

    socket.addEventListener('close', ({ code, reason }) => {
      jsLogger.log('GLADIA CLOSE:', { code, reason })
      clearInterval(this._keepaliveTimeer)
      setTimeout(this._transcriberConnect, 500)
    })

    socket.addEventListener('message', event => {
      // All the messages we are sending are in JSON format
      const message = JSON.parse(event.data.toString())
      jsLogger.log('GLADIA message', message)
      this._handleMessage(message)
    })

    // const _deepgram = createClient(this._dgKey)
    // jsLogger.log('DG: client created')
    // const dgParams = {
    //   ...this._baseDGParams,
    //   sample_rate: this._sampleRate,
    //   language: this._lang
    // }
    // try {
    //   this._dg = _deepgram.listen.live(dgParams)
    //   jsLogger.debug('DG: listen.live', { dgParams })

    //   this._dg.on(LiveTranscriptionEvents.Error, e => {
    //     jsLogger.error('DG ERROR:', e)
    //     clearInterval(this._keepaliveTimeer)
    //     setTimeout(() => this._transcriberConnect(), 1000)
    //   })

    //   this._dg.on(LiveTranscriptionEvents.Open, async () => {
    //     jsLogger.debug('DG connected')
    //     this._dgConnected = true
    //     jsLogger.log('client: connected to websocket')

    //     this._keepaliveTimeer = window.setInterval(this._sendKeepalive, 5000)

    //     if (!this._dg) {
    //       jsLogger.error('Raudio: cannot add listeners, DG is not initialized')
    //       return
    //     }

    //     this._dg.on(
    //       LiveTranscriptionEvents.Transcript,
    //       (data: LiveTranscriptionEvent) => {
    //         // jsLogger.log('DG RESULTS:', data)
    //         const t = get(data.channel.alternatives, [0, 'transcript'])
    //         jsLogger.log('DG: on Transcript', { transcript: t, data })
    //         // const transcript = data.channel.alternatives[0].transcript
    //         this._onSocketMessage(data)
    //       }
    //     )

    //     this._dg.on(LiveTranscriptionEvents.UtteranceEnd, () => {
    //       this._onUtteranceEnd()
    //     })

    //     this._dg.on(LiveTranscriptionEvents.SpeechStarted, data => {
    //       jsLogger.log('DG: Speech started event', data)
    //       this._isSpeaking = true
    //     })

    //     this._dg.on('warning', e => jsLogger.warn('DG WARNING:', e))

    //     this._dg.on('Metadata', e => jsLogger.log('DG METADATA:', e))

    //     this._dg.on('close', e => {
    //       jsLogger.log('DG CLOSE:', e)
    //       // this._dgConnected = false
    //       clearInterval(this._keepaliveTimeer)
    //       setTimeout(this._transcriberConnect, 500)
    //     })
    //   })
    // } catch (e) {
    //   jsLogger.warn('DG connection error', e)
    // }
  }

  private _onUtteranceEnd = () => {
    jsLogger.log('DG: UTTERANCE END received')
    if (this._isSpeaking) {
      if (this._speach !== '') {
        if (this._onFinalDetected) {
          this._onFinalDetected(this._speach)
        }
        this._isSpeaking = false
        this._speach = ''

        this._onSpeach && this._onSpeach('')
      }
    }
  }

  private _onSocketMessage = (received: LiveTranscriptionEvent) => {
    // jsLogger.log('socket message', message)
    // jsLogger.log('socket message:', received)
    const t = get(received, ['channel', 'alternatives', 0, 'transcript'])
    const isFinal = received.is_final
    const speachFinal = received.speech_final
    const timeTrKey = 'time_' + received.start

    if (t !== '') {
      jsLogger.log('DG: transcript received ', {
        // message: t,
        t,
        isFinal,
        speachFinal
      })
      this._onStartTalking && this._onStartTalking()

      this._timeTranscript[timeTrKey] = t
    }

    if (isFinal && t !== '') {
      this._speach = (this._speach + ' ' + t).trim()
      this._onSpeach && this._onSpeach(this._speach)
    } else if (t !== '') {
      this._onSpeach && this._onSpeach((this._speach + ' ' + t).trim())
    }

    if (isFinal && speachFinal) {
      jsLogger.log('DG: speech final detected', {
        speech: this._speach,
        timeTranscript: this._timeTranscript[timeTrKey]
      })
      const phrase =
        this._speach !== '' ? this._speach : this._timeTranscript[timeTrKey]
      if (phrase && phrase.length > 0) {
        this._onFinalDetected && this._onFinalDetected(phrase)
        this._speach = ''
        this._isSpeaking = false
        this._onSpeach && this._onSpeach('')
      }
    }
  }

  stop = () => {
    jsLogger.log('DG: STOP')
    if (this._dg && this._dgConnected) {
      this._dg.requestClose()
      clearInterval(this._keepaliveTimeer)
    }
    this._stopped = true
    this._source && this._source.disconnect()
    this._recorderNode && this._recorderNode.disconnect()
    this._audioContext &&
      this._audioContext.state !== 'closed' &&
      this._audioContext.close()
  }

  setLang = (l: string) => {
    jsLogger.log('DG: set lang', {
      toLang: l,
      currentLang: this._lang,
      isConnected: this._dg && this._dg.isConnected()
    })
    if (this._dg && this._dg.isConnected() && l !== this._lang) {
      jsLogger.log('DG, lang changed', { prevLang: this._lang, newLang: l })
      this._lang = l
      // const dgParams = {
      //   ...this._baseDGParams,
      //   sampleRate: this._sampleRate,
      //   language: this._lang
      // }
      // jsLogger.debug('DG: reconnecting with params:', { dgParams })
      this._dg.requestClose()
      // clearInterval(this._keepaliveTimeer)
      // this._transcriberConnect()
      // this._dg.reconnect(dgParams)
    }
  }

  start = async () => {
    jsLogger.debug('DG: start')
    this._speach = ''
    this._audioContext = new AudioContext({ sampleRate: PREFERRED_SAMPLE_RATE })
    try {
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    } catch (e) {
      this._audioContext = new AudioContext() // some browsers can’t return arbitrary audio sample rate, such as firefox
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    }
    const packetSamples =
      (this._audioContext.sampleRate * PACKET_DURATION) / MILLISECOND
    let port: any
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      const recorder = this._audioContext.createScriptProcessor(BUFFER_SIZE)
      this._source.connect(recorder)
      port = {}
      let queueLength = 0
      const start = Date.now()
      let num = 0
      const queue = new Int16Array(packetSamples)
      recorder.onaudioprocess = function (e) {
        if (port.onmessage) {
          const left = e.inputBuffer.getChannelData(0)
          const right = e.inputBuffer.getChannelData(1) || left
          if (left && right) {
            for (let i = 0; i < left.length; i++) {
              queue[queueLength++] =
                (left[i] + right[i]) * STEREO_FLOAT_TO_MONO_INT16
              if (queueLength === queue.length) {
                queueLength = 0
                port.onmessage({
                  data: {
                    data: queue,
                    log: [Date.now(), Date.now() - start, ++num]
                  }
                })
              }
            }
          }
        }
      }
      recorder.connect(this._audioContext.destination)
    } else {
      const blob = new Blob(
        [
          `
registerProcessor("pcm-processor", class extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.start=Date.now();
	this.num=0;
	this.queue=new Int16Array(${packetSamples});
	this.queueLength=0;
  }
  process(input) {
    const left = input[0][0];
    const right = input[0][1]||left;
	if(left && right){
		for(let i=0;i<left.length;i++){
			this.queue[this.queueLength++]=(left[i]+right[i])*${STEREO_FLOAT_TO_MONO_INT16};
			if(this.queueLength===this.queue.length){
					this.queueLength=0;
					this.port.postMessage({data:this.queue,log:[Date.now(),Date.now()-this.start,++this.num]});
			}
		}
	}
    return true;
  }
});`
        ],
        { type: 'application/javascript; charset=utf-8' }
      )
      const url = URL.createObjectURL(blob)
      await this._audioContext.audioWorklet.addModule(url)
      this._recorderNode = new AudioWorkletNode(
        this._audioContext,
        'pcm-processor'
      )
      this._recorderNode.connect(this._audioContext.destination)
      port = this._recorderNode.port
      this._source.connect(this._recorderNode)
    }

    port.onmessage = ({ data }: { data: any }) => {
      // const blob = new Blob([data.data.buffer])
      if (this._socket && this._socket.readyState === WebSocket.OPEN) {
        this._socket.send(data.data.buffer)
      } else {
        jsLogger.warn('DG: ignore the blob, connected', {
          connected: this._dgConnected
        })
      }
    }

    this._sampleRate = this._audioContext.sampleRate
    this._transcriberConnect()
  }
}

export default GladiaTranscriber
