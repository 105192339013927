import get from 'lodash/get'
import {
  createClient,
  LiveClient,
  LiveTranscriptionEvents,
  LiveTranscriptionEvent
} from '@deepgram/sdk'
import jsLogger from 'js-logger'

const MILLISECOND = 1000
const PREFERRED_SAMPLE_RATE = 16000
const PACKET_DURATION = 500
const BUFFER_SIZE = 2048
const STEREO_FLOAT_TO_MONO_INT16 = 0x10000 / 4 - 1

class SPEECHMATICSTranscriber {
  private _stream: MediaStream
  private _blobs: Blob[] = []
  private _dg?: LiveClient
  private _speach: string = ''
  private _onSpeach?: (s: string) => void
  private _onFinalDetected?: (transcript: string) => void
  private _interactionId: string
  private _source?: MediaStreamAudioSourceNode
  private _recorderNode?: AudioWorkletNode
  private _audioContext?: AudioContext
  private _apiKey?: string
  private _isInitialized: boolean = false
  private _keepaliveTimeer: number = 0
  private _isSpeaking: boolean = false
  private _onStartTalking: () => void
  private _timeTranscript: Record<string, string> = {}
  private _lang = 'en'
  private _sampleRate = 16000
  private _stopped = false
  private _socketUrl?: string
  private _socket?: WebSocket

  constructor (
    stream: MediaStream,
    onSpeach: (s: string) => void,
    onFinalDetected: (transcript: string) => void,
    interactionId: string,
    apiKey: string,
    onStartTalking: () => void,
    lang: string
  ) {
    this._stream = stream
    this._onSpeach = onSpeach
    this._onFinalDetected = onFinalDetected
    this._interactionId = interactionId
    this._apiKey = apiKey
    this._onStartTalking = onStartTalking
    this._lang = lang
    this.start()

    // this._transcriberConnect(dgKey)
  }

  get speach () {
    return this._speach
  }

  private _resetSpeech = () => {
    jsLogger.log('reset speech')
    this._speach = ''
  }

  private _sendKeepalive = () => {
    if (this._dg && this._dg.isConnected()) {
      jsLogger.log('SPEECHMATICS: send keepalive')
      this._dg.keepAlive()
    }
  }

  private _handleMessage = (message: object) => {
    jsLogger.log('SPEECHMATICS: handle message')
    const mType = get(message, 'message', '') as string
    const isFinal = mType === 'AddTranscript'
    if (mType === 'RecognitionStarted') {
      this._isInitialized = true
    }
    let t = get(message, 'metadata.transcript', '').trim()
    if (t === '.' || t === '?' || t === '!') {
      t = ''
    }
    if (mType === 'AddTranscript' || mType === 'AddPartialTranscript') {
      if (t !== '') {
        jsLogger.log('SPEECHMATICS: transcript received ', {
          // message: t,
          t,
          isFinal
        })
        this._onStartTalking && this._onStartTalking()

        // this._timeTranscript[timeTrKey] = t
      }

      if (isFinal && t !== '') {
        this._speach = (this._speach + ' ' + t).trim()
        this._onSpeach && this._onSpeach(this._speach)
      } else if (t !== '') {
        this._onSpeach && this._onSpeach((this._speach + ' ' + t).trim())
      }

      if (isFinal && t !== '') {
        jsLogger.log('SPEECHMATICS: speech final detected', {
          speech: this._speach
          // timeTranscript: this._timeTranscript[timeTrKey]
        })
        const phrase = this._speach
        // this._speach !== '' ? this._speach : this._timeTranscript[timeTrKey]
        if (phrase && phrase.length > 0) {
          this._onFinalDetected && this._onFinalDetected(phrase)
          this._speach = ''
          this._isSpeaking = false
          this._onSpeach && this._onSpeach('')
        }
      }
    }
  }

  private _transcriberConnect = async () => {
    if (this._stopped) {
      jsLogger.debug('SPEECHMATICS exit because already stopped')
      return
    }

    const socketUrl = `wss://eu2.rt.speechmatics.com/v2?jwt=${this._apiKey}`

    const socket = new WebSocket(socketUrl)
    this._socket = socket

    socket.addEventListener('open', () => {
      jsLogger.log('SPEECHMATICS socket connection opened')
      const m = {
        message: 'StartRecognition',
        audio_format: {
          type: 'raw',
          encoding: 'pcm_s16le',
          sample_rate: this._sampleRate
        },
        transcription_config: {
          language: this._lang,
          operating_point: 'enhanced',
          // additional_vocab: ['gnocchi', 'bucatini', 'bigoli'],
          // diarization: 'speaker',
          enable_partials: true,
          max_delay: 1,
          transcript_filtering_config: {
            remove_disfluencies: true
          }
        }

        // translation_config: {
        //   target_languages: ['es', 'de'],
        //   enable_partials: true
        // },
        // audio_events_config: {
        //   types: ['applause', 'music']
        // }
      }
      socket.send(JSON.stringify(m))
    })

    socket.addEventListener('error', error => {
      jsLogger.error('SPEECHMATICS ERROR:', { error })
      setTimeout(() => this._transcriberConnect(), 1000)
    })

    socket.addEventListener('close', ({ code, reason }) => {
      jsLogger.log('SPEECHMATICS CLOSE:', { code, reason })
      clearInterval(this._keepaliveTimeer)
      setTimeout(this._transcriberConnect, 500)
    })

    socket.addEventListener('message', event => {
      // All the messages we are sending are in JSON format
      const message = JSON.parse(event.data.toString())
      jsLogger.log('SPEECHMATICS message', message)
      this._handleMessage(message)
    })

    // const _deepgram = createClient(this._dgKey)
    // jsLogger.log('SPEECHMATICS: client created')
    // const dgParams = {
    //   ...this._baseDGParams,
    //   sample_rate: this._sampleRate,
    //   language: this._lang
    // }
    // try {
    //   this._dg = _deepgram.listen.live(dgParams)
    //   jsLogger.debug('SPEECHMATICS: listen.live', { dgParams })

    //   this._dg.on(LiveTranscriptionEvents.Error, e => {
    //     jsLogger.error('DG ERROR:', e)
    //     clearInterval(this._keepaliveTimeer)
    //     setTimeout(() => this._transcriberConnect(), 1000)
    //   })

    //   this._dg.on(LiveTranscriptionEvents.Open, async () => {
    //     jsLogger.debug('DG connected')
    //     this._dgConnected = true
    //     jsLogger.log('client: connected to websocket')

    //     this._keepaliveTimeer = window.setInterval(this._sendKeepalive, 5000)

    //     if (!this._dg) {
    //       jsLogger.error('Raudio: cannot add listeners, DG is not initialized')
    //       return
    //     }

    //     this._dg.on(
    //       LiveTranscriptionEvents.Transcript,
    //       (data: LiveTranscriptionEvent) => {
    //         // jsLogger.log('DG RESULTS:', data)
    //         const t = get(data.channel.alternatives, [0, 'transcript'])
    //         jsLogger.log('SPEECHMATICS: on Transcript', { transcript: t, data })
    //         // const transcript = data.channel.alternatives[0].transcript
    //         this._onSocketMessage(data)
    //       }
    //     )

    //     this._dg.on(LiveTranscriptionEvents.UtteranceEnd, () => {
    //       this._onUtteranceEnd()
    //     })

    //     this._dg.on(LiveTranscriptionEvents.SpeechStarted, data => {
    //       jsLogger.log('SPEECHMATICS: Speech started event', data)
    //       this._isSpeaking = true
    //     })

    //     this._dg.on('warning', e => jsLogger.warn('DG WARNING:', e))

    //     this._dg.on('Metadata', e => jsLogger.log('DG METADATA:', e))

    //     this._dg.on('close', e => {
    //       jsLogger.log('DG CLOSE:', e)
    //       // this._dgConnected = false
    //       clearInterval(this._keepaliveTimeer)
    //       setTimeout(this._transcriberConnect, 500)
    //     })
    //   })
    // } catch (e) {
    //   jsLogger.warn('DG connection error', e)
    // }
  }

  private _onUtteranceEnd = () => {
    jsLogger.log('SPEECHMATICS: UTTERANCE END received')
    if (this._isSpeaking) {
      if (this._speach !== '') {
        if (this._onFinalDetected) {
          this._onFinalDetected(this._speach)
        }
        this._isSpeaking = false
        this._speach = ''

        this._onSpeach && this._onSpeach('')
      }
    }
  }

  private _onSocketMessage = (received: LiveTranscriptionEvent) => {
    // jsLogger.log('socket message', message)
    // jsLogger.log('socket message:', received)
    const t = get(received, ['channel', 'alternatives', 0, 'transcript'])
    const isFinal = received.is_final
    const speachFinal = received.speech_final
    const timeTrKey = 'time_' + received.start

    if (t !== '') {
      jsLogger.log('SPEECHMATICS: transcript received ', {
        // message: t,
        t,
        isFinal,
        speachFinal
      })
      this._onStartTalking && this._onStartTalking()

      this._timeTranscript[timeTrKey] = t
    }

    if (isFinal && t !== '') {
      this._speach = (this._speach + ' ' + t).trim()
      this._onSpeach && this._onSpeach(this._speach)
    } else if (t !== '') {
      this._onSpeach && this._onSpeach((this._speach + ' ' + t).trim())
    }

    if (isFinal && speachFinal) {
      jsLogger.log('SPEECHMATICS: speech final detected', {
        speech: this._speach,
        timeTranscript: this._timeTranscript[timeTrKey]
      })
      const phrase =
        this._speach !== '' ? this._speach : this._timeTranscript[timeTrKey]
      if (phrase && phrase.length > 0) {
        this._onFinalDetected && this._onFinalDetected(phrase)
        this._speach = ''
        this._isSpeaking = false
        this._onSpeach && this._onSpeach('')
      }
    }
  }

  stop = () => {
    jsLogger.log('SPEECHMATICS: STOP')
    if (this._socket) {
      this._socket.close()
    }
    this._stopped = true
    this._source && this._source.disconnect()
    this._recorderNode && this._recorderNode.disconnect()
    this._audioContext &&
      this._audioContext.state !== 'closed' &&
      this._audioContext.close()
  }

  setLang = (l: string) => {
    jsLogger.log('SPEECHMATICS: set lang', {
      toLang: l,
      currentLang: this._lang,
      isConnected: this._dg && this._dg.isConnected()
    })
    if (this._socket && this._isInitialized && l !== this._lang) {
      jsLogger.log('DG, lang changed', { prevLang: this._lang, newLang: l })
      this._lang = l
      // const dgParams = {
      //   ...this._baseDGParams,
      //   sampleRate: this._sampleRate,
      //   language: this._lang
      // }
      // jsLogger.debug('SPEECHMATICS: reconnecting with params:', { dgParams })
      this._socket.close()
      // clearInterval(this._keepaliveTimeer)
      // this._transcriberConnect()
      // this._dg.reconnect(dgParams)
    }
  }

  start = async () => {
    jsLogger.debug('SPEECHMATICS: start')
    this._speach = ''
    this._audioContext = new AudioContext({ sampleRate: PREFERRED_SAMPLE_RATE })
    try {
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    } catch (e) {
      this._audioContext = new AudioContext() // some browsers can’t return arbitrary audio sample rate, such as firefox
      this._source = this._audioContext.createMediaStreamSource(this._stream)
    }
    const packetSamples =
      (this._audioContext.sampleRate * PACKET_DURATION) / MILLISECOND
    let port: any
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (isSafari) {
      const recorder = this._audioContext.createScriptProcessor(BUFFER_SIZE)
      this._source.connect(recorder)
      port = {}
      let queueLength = 0
      const start = Date.now()
      let num = 0
      const queue = new Int16Array(packetSamples)
      recorder.onaudioprocess = function (e) {
        if (port.onmessage) {
          const left = e.inputBuffer.getChannelData(0)
          const right = e.inputBuffer.getChannelData(1) || left
          if (left && right) {
            for (let i = 0; i < left.length; i++) {
              queue[queueLength++] =
                (left[i] + right[i]) * STEREO_FLOAT_TO_MONO_INT16
              if (queueLength === queue.length) {
                queueLength = 0
                port.onmessage({
                  data: {
                    data: queue,
                    log: [Date.now(), Date.now() - start, ++num]
                  }
                })
              }
            }
          }
        }
      }
      recorder.connect(this._audioContext.destination)
    } else {
      const blob = new Blob(
        [
          `
registerProcessor("pcm-processor", class extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.start=Date.now();
	this.num=0;
	this.queue=new Int16Array(${packetSamples});
	this.queueLength=0;
  }
  process(input) {
    const left = input[0][0];
    const right = input[0][1]||left;
	if(left && right){
		for(let i=0;i<left.length;i++){
			this.queue[this.queueLength++]=(left[i]+right[i])*${STEREO_FLOAT_TO_MONO_INT16};
			if(this.queueLength===this.queue.length){
					this.queueLength=0;
					this.port.postMessage({data:this.queue,log:[Date.now(),Date.now()-this.start,++this.num]});
			}
		}
	}
    return true;
  }
});`
        ],
        { type: 'application/javascript; charset=utf-8' }
      )
      const url = URL.createObjectURL(blob)
      await this._audioContext.audioWorklet.addModule(url)
      this._recorderNode = new AudioWorkletNode(
        this._audioContext,
        'pcm-processor'
      )
      this._recorderNode.connect(this._audioContext.destination)
      port = this._recorderNode.port
      this._source.connect(this._recorderNode)
    }

    port.onmessage = ({ data }: { data: any }) => {
      // const blob = new Blob([data.data.buffer])
      if (
        this._socket &&
        this._socket.readyState === WebSocket.OPEN &&
        this._isInitialized
      ) {
        this._socket.send(data.data.buffer)
      } else {
        jsLogger.warn('SPEECHMATICS: ignore the blob, connected', {
          isInitialized: this._isInitialized
        })
      }
    }

    this._sampleRate = this._audioContext.sampleRate
    this._transcriberConnect()
  }
}

export default SPEECHMATICSTranscriber
