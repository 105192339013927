import { useEffect, useState, useRef } from 'react'
import jsLogger from 'js-logger'
import * as Sentry from '@sentry/react'

import EnterEmail from 'pages/EnterEmail'
import EmailSent from 'pages/EmailSent'
import {
  conversationStart,
  getNavigatorLanguage,
  interactionSetLang
} from 'controllers/main'
import Conversation from 'pages/Conversation'
import Recap from 'pages/RecapV2'
import { TwoFactorT } from 'types/twoFactor'
import { getBackClient, BackClient } from 'controllers/back'
import { DBT } from 'types/internal'
import get from 'lodash/get'

const defaultAvatar = 'heygen'

const App = () => {
  const [initializing, setInitializing] = useState(true)
  const [verificationId, setVerificationId] = useState<string | null>(null)
  const [interactionId, setInteractionId] = useState<string>()
  const [emailSent, setEmailSent] = useState<string>()
  const [isComplete, setIsComplete] = useState(false)
  const chunksRef = useRef<{ user: Blob[]; avatar: Blob[] }>({
    user: [],
    avatar: []
  })
  const [initVersion, setInitVersion] = useState<0 | 1>(0)
  const [variant, setVariant] = useState<TwoFactorT.VariantT>('OpenAI')
  const [isTest, setIsTest] = useState(false)
  const [duration, setDuration] = useState<number>(0)
  const [model, setModel] = useState<string | null>(null)
  const [avatar, setAvatar] = useState<TwoFactorT.AvatarT>(defaultAvatar)
  const [azureKey, setAzureKey] = useState<{ token: string; region: string }>()
  const [heygenKey, setHeygenKey] = useState<string>()
  const [steps, setSteps] = useState<TwoFactorT.StepT[]>([])
  const [videoSavingDisabled, setVideoSavingDisabled] = useState(false)
  const backClientRef = useRef<BackClient>(getBackClient())
  const [recapData, setRecapData] = useState<TwoFactorT.RecapT | null>(null)
  const [lang, setLang] = useState('en')
  const [availableLanguages, setAvailableLanguages] = useState([])
  const [interruptionMode, setInterruptionMode] = useState(
    DBT.INTERRUPT.DISABLED
  )
  const [transcriber, setTranscriber] = useState(DBT.TRANSCRIBER.DEEPGRAM)
  const [transcriberKey, setTranscriberKey] = useState('')

  useEffect(() => {
    let locale
    const windowUrl = new URL(window.location.href)
    const urlLang = windowUrl.searchParams.get('lang')
    if (urlLang) {
      locale = urlLang
    } else {
      const browserLang = getNavigatorLanguage().slice(0, 2).toLowerCase()
      locale = browserLang
    }
    if (locale) {
      setLang(locale)
    }
  }, [])

  const getVariant = () => {
    const url = new URL(window.location.href)
    const v = url.searchParams.get('variant')
    switch (v) {
      case '2':
        return 'OpenAI'
      case '3':
        return 'Custom'
      default:
        return 'LlmSystem'
    }
  }

  useEffect(() => {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('id')
    setVerificationId(id)
    const initV = url.searchParams.get('init')
    const recap = url.searchParams.get('recap')
    const test = url.searchParams.get('test')
    const noVideo = true
    if (test) {
      setIsTest(true)
    }
    const urlLang = url.searchParams.get('lang')

    const variant: TwoFactorT.VariantT = getVariant()
    setVariant(variant)
    const avatarParam = url.searchParams.get('a')
    let _avatar: TwoFactorT.AvatarT = defaultAvatar
    if (avatarParam !== null) {
      const avatar = url.searchParams.get('a')
      _avatar = avatar === '1' ? 'azure' : avatar === '3' ? 'custom' : 'heygen'
      setAvatar(_avatar)
    }

    const model: string | null = url.searchParams.get('model')
    setModel(model)

    jsLogger.debug('session start', { url, id, variant, _avatar })

    if (recap) {
      setIsComplete(true)
      setInteractionId(recap)
      setInitializing(false)
    } else {
      if (initV && initV === 'v1') {
        setInitVersion(1)
      }
      const run = async () => {
        if (id) {
          jsLogger.debug('conversationStart api call start')
          const apires = await conversationStart(
            id,
            variant,
            model,
            _avatar,
            urlLang
          )
          jsLogger.debug('conversationStart api call end')
          if (apires) {
            if (apires.interactionId) {
              backClientRef.current.interactionStarted(
                apires.interactionId,
                noVideo
              )
              jsLogger.log('conversationStart interactionId', {
                interactionId: apires.interactionId
              })
              setInteractionId(apires.interactionId)
              Sentry.setContext('session', {
                verificationId: id,
                interactionId: apires.interactionId
              })
            }
            apires.steps && setSteps(apires.steps)
            apires.transcriber && setTranscriber(apires.transcriber)
            apires.transcriberKey && setTranscriberKey(apires.transcriberKey)
            apires.heygenKey && setHeygenKey(apires.heygenKey)
            apires.azureKey && setAzureKey(apires.azureKey)
            apires.lang && setLang(apires.lang)
            apires.interruptionMode &&
              setInterruptionMode(apires.interruptionMode)
            apires.availableLanguages &&
              setAvailableLanguages(apires.availableLanguages)
          }
        }
        setInitializing(false)
      }
      run()
    }
  }, [])

  const onConversationFinished = () => {
    jsLogger.log('onConverstionFinished')
    if (interactionId) {
      jsLogger.debug('conversation finished', { interactionId })
    }
    setIsComplete(true)
  }

  const onChangeLang = (l: string) => {
    jsLogger.debug('onChangeLang', { lang: l })
    if (interactionId) {
      interactionSetLang(interactionId, l)
      setLang(l)
    }
  }

  const handleChunk = async (
    chunk: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => {
    chunksRef.current[role].push(chunk)
    if (!videoSavingDisabled) {
      backClientRef.current.sendVideoChunk(chunk, mimeType, role)
    }
    // chunksRef.current[role].push(chunk)
    // if (interactionId) {
    //   const storagePath = interactionId
    //   try {
    //     const concatenatedBlob = new Blob(chunksRef.current[role], {
    //       type: mimeType
    //     })
    //     chunksRef.current[role] = []
    //     const id = generateId()
    //     setUploadQueue(v => {
    //       v.push(id)
    //       return v
    //     })
    //     await uploadBlobToStorage(
    //       concatenatedBlob,
    //       `${storagePath}/${role}_chunks/${id}`
    //     )
    //     setUploadQueue(v => {
    //       return without(v, id)
    //     })
    //   } catch (error) {
    //     jsLogger.error('Error uploading chunks:', error)
    //   }
    // }
  }

  const renderContent = () => {
    if (initializing) {
      return null
    } else if (isComplete && interactionId) {
      return (
        <Recap
          interactionId={interactionId}
          chunks={chunksRef.current}
          recapData={recapData}
          isTest={isTest}
          locale={lang}
        />
      )
    } else if (interactionId && transcriberKey) {
      return (
        <Conversation
          verificationId={verificationId}
          interactionId={interactionId}
          onConversationFinished={onConversationFinished}
          handleChunk={handleChunk}
          initVersion={initVersion}
          isTest={isTest}
          setDuration={setDuration}
          azureKey={azureKey}
          heygenKey={heygenKey}
          avatar={avatar}
          steps={steps}
          setSteps={setSteps}
          setRecapData={setRecapData}
          interruptionMode={interruptionMode}
          lang={lang}
          availableLanguages={availableLanguages}
          onChangeLang={(l: string) => onChangeLang(l)}
          transcriber={transcriber}
          transcriberKey={transcriberKey}
        />
      )
    } else if (emailSent) {
      return <EmailSent email={emailSent} locale={lang} />
    } else {
      return (
        <EnterEmail
          onComplete={(email: string) => setEmailSent(email)}
          variant={variant}
          model={model}
          isTest={isTest}
          avatar={avatar}
          locale={lang}
        />
      )
    }
  }

  return (
    <div className='w-full h-full relative flex flex-col justify-center items-center no-scrollbar'>
      {renderContent()}
    </div>
  )
}

export default App
