import { FC } from 'react'

import { ReactComponent as IconLogo } from 'assets/FaceSignAILogo.svg'
import { getString } from 'controllers/localization'

type Props = {
  email: string
  locale: string
}

const EmailSent: FC<Props> = ({ email, locale }) => {
  const heading = (
    <div className='flex flex-row w-full landscape:px-24 portrait:pl-2 portrait:pr-4 items-center justify-between py-6 w-full'>
      <a href='https://facesign.ai/'>
        <IconLogo width='138' height='32' />
      </a>
      <div>
        <a
          className='text-[#0099FF] portrait:text-sm landscape:text-lg font-medium'
          href='https://facesign.ai/'
        >
          {getString('send_email_learn_more', locale)}
        </a>
      </div>
    </div>
  )

  const title = (
    <div className='flex flex-row w-full justify-center landscape:mt-24 portrait:mt-12'>
      <span className='text-gray-800 font-medium text-2xl'>
        {getString('email_sent_title', locale)}
      </span>
    </div>
  )

  const description = (
    <div className='flex flex-col w-full justify-center items-center landscape:mt-12 portrait:mt-8'>
      <p className='text-blue-800 font-bold text-xl'>
        {getString('email_sent_link_sent', locale)}:
      </p>
      <p className='text-blackAlpha-700 text-xl'>{email}</p>
      <p className='text-blackAlpha-800 font-medium text-md landscape:mt-12 portrait:mt-8'>
        {getString('email_sent_open_link', locale)}
      </p>
    </div>
  )

  const subText = (
    <div className='landscape:mt-24 portrait:mt-16 flex flex-col portrait:px-12'>
      <p className='text-blackAlpha-500 text-sm text-center'>
        {getString('email_sent_check_junk_folder', locale)}
      </p>
    </div>
  )

  return (
    <div className='h-full w-full'>
      <div className='flex w-full h-full flex-col flex-1 items-center'>
        {heading}
        <hr className='border-gray-100 w-full' />
        {title}
        {description}
        {subText}
      </div>
    </div>
  )
}

export default EmailSent
